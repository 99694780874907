import { FullStory } from '@fullstory/browser';
import { fullStoryEnabled } from './initFullStory';
import { AccountInfo } from '@azure/msal-browser';
import { useEffect } from 'react';
import { useJsonEqualState } from '@/hooks/useJsonEqualState';
import { useMsalAccount } from '@/contexts/TokenContext/useMsalAccount';

interface FullStoryIdentity {
    uid: string;
    properties: {
        displayName?: string;
        email?: string;
    };
}

export function logUserToFullStory(identity: FullStoryIdentity) {
    // No data
    if (!identity) {
        return;
    }

    // Full Story is currently disabled
    if (!fullStoryEnabled) {
        return;
    }

    // Log user information to FullStory
    FullStory('setIdentity', identity);
}

function getFullStoryIdentity(account: AccountInfo): FullStoryIdentity {
    const uid = account?.homeAccountId;
    if (!uid) {
        return undefined;
    }

    const identity: FullStoryIdentity = {
        uid: uid,
        properties: {
            displayName: account.name,
            email: account.username,
        },
    };

    return identity;
}

export function FullStoryUserLogger(): JSX.Element {
    // Get the account from MSAL
    const account = useMsalAccount();
    const [identity, setIdentity] = useJsonEqualState<FullStoryIdentity>();

    // Keep track of the parts of the account info that we need to send to FullStory
    useEffect(() => {
        const newIdentity = getFullStoryIdentity(account);
        setIdentity(newIdentity);
    }, [account, setIdentity]);

    // If the parts we care about change, send that fo FullStory
    useEffect(() => {
        logUserToFullStory(identity);
    }, [identity]);

    // Just return as an empty element
    return null;
}
