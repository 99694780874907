import { init, SnippetOptions } from '@fullstory/browser';
import { config } from '@/common/util/config';

export const fullStoryEnabled = false;

const orgIds: Record<string, string> = {
    Production: 'SZ9NP',
    QA: 'QCMR5',
};

function getFullStoryOrgId() {
    // Production
    if (config.isProd) {
        return orgIds['Production'];
    }

    // QA / UAT
    if (config.isUAT || config.isQA) {
        return orgIds['QA'];
    }

    console.log('Full Story is not enabled for this enviornment');

    return undefined;
}

function getFullStoryOptions(): SnippetOptions {
    // Need to have an org id to initialize
    const orgId = getFullStoryOrgId();
    if (!orgId) {
        return undefined;
    }

    const options: SnippetOptions = {
        orgId: orgId,
    };

    return options;
}

// Initialize full story for the environment (if needed)
export function initFullStory() {
    // Check to see if we want it enabled
    if (!fullStoryEnabled) {
        console.log('Full Story is disabled');
        return;
    }

    const options = getFullStoryOptions();
    if (!options) {
        return;
    }

    init(options);
}
